import React, { useEffect } from "react"
import { handleSilentRenew } from "../../utilities/userManager"

const Silent = () => {
  useEffect(() => {
    handleSilentRenew()
  })

  return (
    <div>
    </div>
  )
}

export default Silent
